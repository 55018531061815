.chatbot{
    cursor: pointer;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
    width: 60px;
    height: 60px;
    position: fixed;
    bottom: 60px;
    right: 40px;
    background-color: rgb(247, 152, 233);
}
.chatbot>img{
    border-radius: 50%;
    width: 80%;
    height: 80%;
}
.chatbot{
transition: transform 0.2s ease-in-out;
  }
  .chatbot:hover{
    transform:scale(1.20);
}
.chat{
    /* top: 50px; */
    bottom: 150px;
    margin-right: 20px;
    background-color: #f8f5e9;
    width: 20vw;
    max-width: 100%;
    height: 50vh;
    padding: 10px;
    position: fixed;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 9000;
    border-radius: 10px;
    /* opacity:0; */
    transition:opacity 0.5s ease-in-out;
    right: 0;
    background-image: linear-gradient(to bottom,rgb(150, 150, 244),pink);
}

/* chathead with close button */

.chat>.chat-head{
    
    font-size: x-large;
    color: rgb(50, 2, 43);
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.1;
    border-bottom: 1px solid grey;
}

.close{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
    position: fixed;
    right: 40px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #f87d48;
    display: flex;
    justify-content:center ;
    align-items: center;
}
.close>.close1{
    width: 10px;
    height: 3px;
    background-color: #f9faf4;
}

/* chatbody */

.chat-body{
    overflow-y:scroll;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    flex: 0.8;
}
/* .chat-body::-webkit-scrollbar {
    display: none;
  }
.chat-body {
    -ms-overflow-style: none;
    scrollbar-width: none;  
} */

.chat-body .msg-input{
    border-radius: 10px;
    padding: 5px;
    margin: 5px;
    align-self: end;
    background-color: #a7eeb0;
}
.chat-body .msg-output{
    border-radius: 10px;
    padding: 5px;
    margin: 5px;
    align-self: start;
    background-color: #bfeef7;
}
.msg-output>label{
    display: block;
}
.msg-output>a{
    display: block;
    color: black;
}

/* chatinput */

.chat-input{
    display: flex;
    flex: 0.1;
}
.chat-input>input{
    border: none;
    flex: 0.8;
    font-size: x-small;
}
.chat-input>input:focus{
    outline: none;
}
.chat-input>button{
    font-size:1vw;
    color: rgb(255, 255, 255);
    flex: 0.2;
    border: none;
    background-color:#c58aea;
     cursor: pointer;
}

/* chatbtn class for the chatbutton image */

.chat-btn{
    width: 60px;
    padding: 10px;
    position:fixed;
    right: 0px;
    bottom: 0px;
    cursor: pointer;

}

.chat-btn:hover{
    transform: scale(1.2,1.2);
}
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
@media  only screen and (max-width:800px) {
    .chat{
        width: 70vw;
        height: 30vh;
    }
}
