.cartdetails{
    min-height: calc(100vh - 108px);
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 20px;
    /* background-image: linear-gradient(to right,pink,rgb(240, 218, 52)); */
}
.items-cart{
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction:column ;
    align-items: center;
    gap: 8px;
    border-bottom: 2px solid grey;
}
.item-c{
    border-bottom: 1px solid grey;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #fafafa;
    padding: 10px;
    width: 50vw;
    /* border: 2px solid black; */
    display: flex;
    gap: 20px;
}
.item-c>img{
max-width:150px ;
height: auto;
}
.item-c>div{
    padding-left: 10px;
    border-left: 1px solid grey;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 10px;
}
.item-c>div>div{
    display: flex;
    gap: 20px;
}
.item-c>div>div>div{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    width: max-content;
    background-color: rgb(225, 248, 242);
}
.item-c>div>div>div{
    /* padding: 3px; */
    padding-left: 8px;
    padding-right: 8px;
gap: 15px;
    /* border: 2px solid black; */
    display: flex;
    align-items: center;
}

@media only screen and (max-width:800px){
    .item-c{
        flex-direction: column;
    }
    .item-c>img{
        align-self: center;
    }
    .item-c>div{
        padding-left: 0;
        padding-top: 10px;
        border-left: none;
        border-top:1px solid grey;
    }
}