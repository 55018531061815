.checkout{
    min-height: calc(100vh - 108px);
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
    /* background-color: lightgray; */
    /* background-image: linear-gradient(to right,pink,rgb(240, 218, 52)); */
}
.checkout>.addresses{
    padding-bottom: 20px;
    gap: 9px;
    border-bottom: 2px solid grey;
    display: flex;
    flex-direction: column;
    /* padding: 30px; */
/* background-color: #f1f1f1; */
}
.addresses>.address{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    width: 80%;
    border-radius: 10px;
    background-color: #ffff;
    padding: 40px;
    display: flex;
    gap: 10px;

}
.address>input{
    /* flex:0.1; */
    padding: 10px;
}
.address>span{
    /* flex:0.9; */
}