.login_page{
    width: 100vw;
    height: calc(100vh - 69px);
    background-image:url("./../../public/images/back2.jpg") ;
    background-color:rgba(0, 0, 0, 0.8);
    /* background-size:cover; */
display:flex ;
/* flex-direction: column; */
justify-content: center;
}

.login_page>.loginbox{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 15vh;
    background-color: #273263;
    align-items: center;
    /* border: 2px solid black; */
 display: flex;
 flex-direction: column;
 width: 30vw;
 
 height: fit-content;
 border-radius: 10px;
}
.loginbox>p{
    margin: 10px 0px 10px 0px;
    text-align: center;
    font-size: xx-large;
    color: rgb(142, 236, 142);
    text-decoration: underline;
}
.loginbox>.login_sign{
    margin: 30px 0px 30px 0px;
    align-self: center;
    /* justify-content: center; */
    align-items: center;
    width: 40%;
    height: 40px;
    padding: 0;
    margin: 0;
display: flex;
border-radius: 30px;
background-color: rgb(88, 199, 227);
}

.loginbox>.login_sign>div{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 30px;
     flex:0.5;
}
.loginbox>.login_sign>div:hover{
    background-color: #fff;
}
.loginbox>.detailsLogin{
    /* background-color: #d3c27e; */
    margin: 30px 0px 30px 0px;
    display: flex;
    flex-direction: column;
}
.detailsLogin>input{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-size: 1em;
    border-radius:5px;
    background-color: #fffbfb;
    margin: 10px 0px 10px 0px;
    padding: .4em;
    border: none;
    height: 30px;
}
.detailsLogin>input:focus{
    outline: none;
}
.detailsLogin>button
{
    margin: 10px 0px 10px 0px;
        height: 40px;
        border-radius: 30px;
        cursor: pointer;
        border: none;
        background-color: #7df085;
        font-weight: 500;
        font-size: larger;
        transition: background-color 0.5s ease-in-out;
}
.detailsLogin>button:hover{
    /* transition: all 1s ease-in-out; */
    background-color:rgb(192, 111, 207);
    color: #ffff;
}

@media  only screen and (max-width:900px) {
.login_page>.loginbox{
    width: 90vw;
}
    
}