.itempage{
    display: flex;
    flex-direction: column;
    padding: 20px;
    /* background-color: lightgray; */
}
.itempage>span{
    border-bottom: 6px solid pink;
    /* width: 100px; */
 margin-left: 10px;
 font-size: xx-large;
}
.itemset{
    /* align-self: center; */
    display: flex;
    
    flex-wrap: wrap;
    margin-top: 10px;
    width: 100%;
    /* gap: 27px; */
    height: max-content;
    /* border: 2px solid black; */
    padding: 5px;
}
.item{
    border-radius: 10px;
    cursor: pointer;
    margin: 6px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    /* box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset; */
    padding: 3px;
    height: max-content; 
    width:250px ;
    display: flex;
    flex-direction: column;
    transition:transform 0.2s ease-in-out;
    /* background-color: #ffff; */
    /* border: 1px solid grey; */
}
.item:hover{
    transform:scale(1.05);
}
.item>.itemimage{
    /* display: flex;
    justify-content: center; */
    position: relative;
    /* border-bottom: 1px solid grey; */

}
.itemimage>.imgholder{
    position: relative;
    overflow: hidden;
    height: 320px;
}
.itemimage>.imgholder>img{
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    object-fit: cover;
}
.item-det{
    display: flex;
    flex-direction: column;
    /* background-color: #e7cdea; */
}
.itemimage>.rating-det{
    position: absolute;
    bottom: 15px;
    left: 10px;
}
.rating-det{
    border-radius: 5px;
    font-size: small;
    font-weight: 600;
    padding: 3px;
    width: 50%;
    background-color: #fdfbf8;
    border: 1px solid grey;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.rating-det>.star-val{
    /* padding: 3px; */
    padding-right: 5px;
    display: flex;
    border-right: 2px solid black;
    align-items: center;
    
}
.rating-det>.star-val>.star-ic{
    color: green;
    margin-left: 2px;
    font-size: 1rem;
}
.item-det>.brand-name
{
    font-weight: 700;
}
.item-det>.item-title
{
    font-weight: 500;
    color: rgb(107, 106, 106);
}
.item-det>.priceall{
    display: flex;
    /* justify-content: space-between; */
}
.item-det>.priceall>.actprice{
    font-weight: 500;
    margin-right: 7px;
}
.item-det>.priceall>.disprice{
    /* font-weight: 500; */
    color: grey;
    font-size: smaller;
    margin-top: 2px;
    text-decoration: line-through;
    margin-right: 7px;
}
.item-det>.priceall>.dis{
    font-size: smaller;
    margin-top: 2px;
    color: rgb(95, 70, 8);
    /* margin-right: 7px; */
}
@media only screen and (max-width:600px) {
    .itemset{
        align-self: center;
        /* width: 280px; */
        /* align-items: center; */
        justify-content: center;
        padding: 0;
    }
    .itempage>span{
        border-bottom: 0px;
    }
}
