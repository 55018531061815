.paymentpage{
    min-height: calc(100vh - 108px);
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 20px;
    
    /* background-image: linear-gradient(to right,pink,rgb(240, 218, 52)); */
}
.payopt
{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px;
    background-color: grey;
}
.payopt input{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
    border-radius: 10px;
}
.payopt input:focus{
    outline: none;

}
.payopt>select{
    border-radius: 10px;
    cursor: pointer;
    border: none;
    background-color: #fcfcfc;
    padding: 10px;
    /* width: 200px; */
}
.payopt>select:focus{
    outline: none;
}
.type>input{
padding: 10px;
}
.type{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.creddeb>div{
    display: flex;
    gap: 20px;
    /* justify-content: space-around; */
}
.creddeb>div input{
    padding: 10px;
}
.creddeb>div>input
{
    width: 100px;
}
.creddeb>div>div{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
 background-color: #fff;
}
.creddeb>div>div>input{
box-shadow: none;
width: 40px;
text-align: center;
}
.detauth{
    /* position: relative; */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    /* width: 20vw; */
    height: 40vh;
    width: 50vw;
    padding: 20px;
    position: fixed;
    gap: 10vh;
    top: 50%;
    /* align-items: center; */
    justify-content: center;
    /* justify-content:space-around ; */
left: 50%;
transform: translate(-50%, -50%);
    background-color:#eef7f5;

}
.detauth>div{
    align-self: center;
    display: flex;
    /* align-items: center; */
    gap: 8px;
}
.detauth>div>input{
    padding: 10px;
    height: 30px;
}
.detauth>div>span{
    background-color: #db6868;
    padding: 10px;
    height: 30px;
}
@media only screen and (max-width:540px) {
    .detauth{
        width: 75vw;
    }
    
}
