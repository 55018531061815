.wishlistpg{
padding: 10px;
        min-height: calc(100vh - 108px);
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding: 20px;
        
        /* background-image: linear-gradient(to right,pink,rgb(240, 218, 52)); */

    /* text-decoration: ; */
}
.wishlistpg>.wishitem{
    gap:10px;
align-items: center;
display: flex;
flex-direction: column;
justify-content: center;
}