.mainbar
{
    
    height: max-content;
    padding: 10px;
}
.categories{
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.categories img{
    cursor: pointer;
}
.categories>.cloth-cat{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
}
.categories>.cloth-cat>div{
    display: flex;
    flex-direction:column ;
}
.categories>.cloth-cat>div>img{
    width: 95vw;
}
.categories>.miscat{
    display: flex;
    gap: 4px;
}
.categories>.miscat>div{
    display: flex;
    flex-direction:column ;
}
.categories>.miscat>div>img{
    max-width: 32.4vw;
    height: 800px;
}
.miscat>div{
    background-color:#e0dfdf;
    color: black;
    text-align: center;
}
.cloth-cat>div{
    background-color:#e0dfdf;
    /* height: 20px; */
    color: black;
    text-align: center;
}
.sections{
    margin-bottom: 4px;
    border-top: 2px solid rgba(54, 53, 53, 0.712);
    border-bottom: 2px solid rgba(54, 53, 53, 0.712);
    display: none;
    
    padding: 4px;
}
.sections>span{
    cursor: pointer;
    border-radius: 10px;
    padding: 3px;
    font-size: large;
    font-weight: 500;
    color: rgb(120, 18, 129);
}
@media only screen and (max-width:600px) {
    .categories>.miscat{
        display: flex;
        flex-direction: column;

        gap: 4px;
    }
    .categories>.miscat>div>img{
    max-width: 99vw;
}
.sections{
    display: flex;
    justify-content: space-between;
}
}

abc{
    color: rgb(13, 108, 108);
}
