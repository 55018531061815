.Loading
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: calc(100vh - 80px);
}
.Loading>.loads
{
    /* display: block; */
    padding: 20px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.Loading .load{
 width: 100px;
 height:100px;
 border-top: 8px solid blue;
 border-radius: 50%;
 animation: spin 1s linear infinite;
}
.Loading>p{
    font-size: x-large;
    color: rgb(243, 12, 50);
}
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }